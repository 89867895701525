<template>
  <b-container id="taxa">
    <div class=" taxa-header
  ">
      <h1>Higher Taxa</h1>
      <b-row>
        <b-col cols="12" md="6">
          <p>
            This database does not provide detailed accounts for higher taxa.
            However, for each group we link to pages on Wikipedia, indicated by a W.
            The family links will list all species within that family.
            Note that a hierarchical system as shown here does not reflect the actual relationships among reptiles.
          </p>
        </b-col>
        <b-col cols="12" md="6">
          <p>
            Also, there are additional clades that are not shown here, e.g. birds (a subset of archosaurs) and fossil
            clades.
            Take a look at the phylogenies of Squamates and turtles for more details.
          </p>
        </b-col>
      </b-row>
      <b-tabs class="mt-4" v-model="tabIndex"
              active-nav-item-class="text-info"
              active-tab-class="text-success"
              content-class="mt-4">
        <b-tab v-for="order in orders" :title="order" :key="orders.indexOf(order)+'-'+order">
          <phylo-list :order="orders.indexOf(order)"></phylo-list>
        </b-tab>
      </b-tabs>
    </div>
  </b-container>
</template>

<script>
import PhyloList from '@/components/taxa/PhyloList'

export default {
  name: 'Taxa',
  components: {
    PhyloList
  },
  data () {
    return {
      tabIndex: 0,
      orders: ['Testudines', 'Rhynchocephalia', 'Squamata', 'Crocodylia']
    }
  },
  activated () {
    switch (this.$route.name.toLowerCase()) {
      case 'lizards':
        this.tabIndex = 2
        break
      case 'snakes':
        this.tabIndex = 2
        break
      case 'tuataras':
        this.tabIndex = 1
        break
      case 'crocodiles':
        this.tabIndex = 3
        break
      case 'amphisbaenia':
        this.tabIndex = 2
        break
      case 'turtles':
        this.tabIndex = 0
        break
    }
    this.scrollTo()
  },
  methods: {
    scrollTo() {
      let id = document.getElementById(this.$route.name.toLowerCase())
      setTimeout(function () {
        id.scrollIntoView()
      }, 100)
    }
  }
}
</script>
