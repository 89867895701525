<template>
  <div id="phylo-list" class="ml-3">
    <h3 v-if="orderData.alias" :id="orderData.alias.toLowerCase()">{{ orderData.alias }}</h3>
    <div v-for="suborder in orderData.suborders" class="list-lvl1">
      <h3 v-if="suborder.alias" :id="suborder.alias.toLowerCase()">{{ suborder.alias }}</h3>
      <div class="ml-3">
        <h5 class="mt-3">Suborder {{ suborder.name }}</h5>
        <ul v-for="superfamily in suborder.superfamilies">
          <li class="list-lvl2">
            <span class="superfamily-name"> Superfamily {{ superfamily.name }}</span>
            <ul class="mt-1">
              <li v-for="family in superfamily.families" class="list-lvl3">
                <span class="family-name">{{ family.name }}</span><span
                class="family-description">{{ family.description }}</span>
                <div class="actionBtns">
                  <b-button size="sm" :href="'/search?taxa=' + family.name" type="submit" variant="light">
                    <font-awesome-icon icon="search" alt="Search the database for this family"/>
                  </b-button>
                  <b-button size="sm" :href="'http://en.wikipedia.org/wiki/' + family.name" variant="light">
                    <font-awesome-icon :icon="['fab', 'wikipedia-w']" alt="Search the database for this family"/>
                  </b-button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import phylo_orders from '@/assets/phylo_orders.json'

export default {
  name: 'PhyloList',
  props: {
    order: Number
  },
  computed: {
    orderData () {
      return phylo_orders[this.order]
    }
  }
}
</script>

<style lang="scss">
#phylo-list {
  h3 {
    color: $black;
  }

  .list-lvl2 {
    margin-bottom: 1em;
    list-style: none;

    span {
      color: $color-secondary;
    }

    .list-lvl3 {
      padding: 0.25em;
      list-style: none;

      .family-name {
        color: $black;
        font-weight: 500;
      }

      .family-description {
        padding: 0 0.5rem 0 0.25rem;
        color: $color-secondary;
      }

      &:hover {
        background-color: $grey-3;
      }

      .actionBtns {
        display: inline-block;

        > button, > a {
          background-color: transparent;
          border-color: transparent;
          font-size: 1em;

          &:hover {
            color: #212529;
            background-color: #e2e6ea;
            border-color: #dae0e5;
          }
        }
      }
    }
  }
}
</style>
